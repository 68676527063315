import { TrustpilotSettings } from '../../shared/types/tenantConfig';

export function getTrustpilotSettings(isMydays: boolean): TrustpilotSettings {
    return {
        dataTemplateId: '5419b6a8b0d04a076446a9ad',
        ...(isMydays
            ? {
                  dataBusinessUnitId: '49607cbd000064000503feef',
                  url: 'https://de.trustpilot.com/review/www.mydays.de',
              }
            : {
                  dataBusinessUnitId: '4bded312000064000506c143',
                  url: 'https://de.trustpilot.com/review/www.jochen-schweizer.de',
              }),
    };
}
