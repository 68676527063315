/* eslint-disable react/react-in-jsx-scope */
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Box } from '@jsmdg/yoshi';
import classNames from 'classnames';

import styles from './Footer.module.scss';
import { Trustpilot } from './Trustpilot/Trustpilot';
import { Newsletter } from './Newsletter';
import { SocialApps } from './SocialApps/SocialApps';
import { FooterLinkList } from './FooterLinkList/FooterLinkList';
import { PaymentMethods } from './PaymentMethods/PaymentMethods';
import { LinksBlock } from './LinksBlock/LinksBlock';
import { CheckoutFooter } from './CheckoutFooter/CheckoutFooter';
import { FooterContext } from '../types';

const Footer = (): JSX.Element => {
    const {
        locale,
        enabledPaymentMethods,
        isTrustpilotEnabled,
        isNewsletterEnabled,
        areSocialAppsEnabled,
        areFrequentSearchTermsAndHelpEnabled,
        showCheckoutFooter,
    } = useFragmentContext<FooterContext>();

    return (
        <Box isFluid>
            {showCheckoutFooter ? (
                <CheckoutFooter />
            ) : (
                <footer>
                    {isTrustpilotEnabled && <Trustpilot locale={locale} />}
                    {isNewsletterEnabled && <Newsletter />}
                    {areSocialAppsEnabled && <SocialApps />}
                    <div className={classNames('p-3x pb-1x', styles.highlightedBg)}>
                        <FooterLinkList />
                        <PaymentMethods
                            enabledMethods={enabledPaymentMethods}
                            className="justify-content-center"
                        />
                    </div>
                    {areFrequentSearchTermsAndHelpEnabled && <LinksBlock />}
                </footer>
            )}
        </Box>
    );
};

export { Footer };
