import { Image, PaymentPill, useIntersection } from '@jsmdg/yoshi';
import { defineMessages, useIntl } from 'react-intl';
import { ReactNode, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Brand, Tenant } from '@jsmdg/tenancy';

import { PaymentMethod } from '../../../shared/enums/paymentMethods';
import { paymentMethodLogoMap } from './paymentMethodLogoMap';
import styles from './PaymentMethods.module.scss';
import { FooterContext } from '../../types';
import { useCheckUrl } from '../../hooks/useCheckUrl';

const messages = defineMessages({
    paymentTypeInvoice: {
        defaultMessage: 'Rechnung',
    },
});

type Props = {
    enabledMethods: PaymentMethod[];
    className?: string;
};

const PaymentMethods = ({ enabledMethods, className }: Props): JSX.Element => {
    const intl = useIntl();
    const wasSticky = useRef(false);
    const [isIntersecting, setTargetElement] = useIntersection({});
    const { tenant } = useFragmentContext<FooterContext>();
    const isMydays = new Tenant(tenant).getBrand() === Brand.Mydays;
    const targetElement = useRef(null);
    const pathname = typeof window !== 'undefined' ? window.location.pathname : '';

    const cartPage = useCheckUrl(pathname);

    useEffect(() => {
        if (!targetElement.current) {
            return;
        }

        const event = new CustomEvent('paymentVisibilityChange', {
            // Create and dispatch a custom event with the visibility status.
            detail: {
                isVisible: isIntersecting,
            },
        });
        window.dispatchEvent(event);
    }, [isIntersecting]);

    let isSticky = false;

    if (!isIntersecting && wasSticky.current) isSticky = true;
    else {
        if (isIntersecting) wasSticky.current = true;
        isSticky = false;
    }

    return (
        <div ref={node => node && setTargetElement(node)}>
            <div
                ref={targetElement}
                id="payment-methods"
                className={classnames(
                    'pt-2x',
                    {
                        [styles.sticky]: isSticky && isMydays && cartPage,
                    },
                    {
                        'bottom-0 w-100 d-flex justify-content-center':
                            isSticky && isMydays && cartPage,
                    },
                    {
                        'pl-2-5x ': !isSticky,
                    },
                )}
            >
                <ul className={classnames('d-flex flex-wrap p-0', styles.listWrapper, className)}>
                    {enabledMethods.map(
                        (method): ReactNode => (
                            <li key={method} className="mb-2x mx-sm-1x mx-0-5x">
                                <PaymentPill text={method === PaymentMethod.Invoice}>
                                    {method === PaymentMethod.Invoice ? (
                                        <span>
                                            {intl.formatMessage(messages.paymentTypeInvoice)}
                                        </span>
                                    ) : (
                                        <Image
                                            alt={paymentMethodLogoMap[method].alt}
                                            src={paymentMethodLogoMap[method].icon}
                                            className={styles.small}
                                            loading="lazy"
                                        />
                                    )}
                                </PaymentPill>
                            </li>
                        ),
                    )}
                </ul>
            </div>
        </div>
    );
};

export { PaymentMethods };
