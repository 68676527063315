import {
    FacebookIconSrc,
    Image,
    InstagramIconSrc,
    PinterestIconSrc,
    YoutubeIconSrc,
} from '@jsmdg/yoshi';

import { SocialIconName } from '../../../../shared/enums/socialIconName';
import { trackSocialNetworkIconClick } from '../../../helper/trackingFooter';

const icons = {
    [SocialIconName.FaceBook]: { alt: 'Facebook', src: FacebookIconSrc },
    [SocialIconName.Instagram]: { alt: 'Instagram', src: InstagramIconSrc },
    [SocialIconName.Pinterest]: { alt: 'Pinterest', src: PinterestIconSrc },
    [SocialIconName.Youtube]: { alt: 'Youtube', src: YoutubeIconSrc },
};

type IconProps = {
    name: keyof typeof icons;
    color?: string | null;
};

const SocialIcon = ({ name, color = null }: IconProps): JSX.Element => {
    const { src, alt } = icons[name];

    return (
        <Image
            color={color ?? 'currentColor'}
            width={24}
            height={24}
            src={src}
            alt={alt}
            loading="lazy"
            onClick={() => trackSocialNetworkIconClick(name)}
        />
    );
};

export { SocialIcon, icons };
export type { IconProps };
