import { TenantEnum } from '@jsmdg/tenancy';

import { LinkBlock } from '../../shared/types/tenantConfig';

export function getLinksBlock(tenant: TenantEnum): LinkBlock[] {
    switch (tenant) {
        default:
        case TenantEnum.JS_DE:
            return [
                {
                    title: 'searchTermsTitle',
                    list: [
                        { url: '/geschenke/geschenkgutscheine/l/pvfi6', messageId: 'voucher' },
                        {
                            url: '/essen-trinken/dark-dinner_fuer_2/p/o425a',
                            messageId: 'darkDinner',
                        },
                        { url: '/geschenke/ausgefallene-geschenke/l/cyk41', messageId: 'fancy' },
                    ],
                },
                {
                    list: [
                        { url: '/geschenke/tipps-gegen-langeweile/l/mrdsj', messageId: 'tips' },
                        { url: '/geschenke/besondere-geschenke/l/sqae7', messageId: 'special' },
                        {
                            url: '/essen-trinken/candle-light-dinner/p/ucxo1',
                            messageId: 'candleDinner',
                        },
                    ],
                },
                {
                    list: [
                        {
                            url: '/fliegen-fallen/fallschirmspringen/l/695dr',
                            messageId: 'skydiving',
                        },
                        {
                            url: '/kunst-kultur-lifestyle/Fotoshooting/p/3uc6o',
                            messageId: 'photoShoot',
                        },
                        { url: '/motorpower/quad-fahren/l/5sodr', messageId: 'quadTour' },
                    ],
                },
                {
                    list: [
                        {
                            url: '/geschenke/aussergewoehnliche-geschenke/l/tg8hi',
                            messageId: 'extraordinary',
                        },
                        { url: '/geschenke/geschenkideen/l/rzm9g', messageId: 'ideas' },
                        { url: '/arena/jochen-schweizer-arena/l/eh93i', messageId: 'arena' },
                    ],
                },
                {
                    title: 'onlineHelpTitle',
                    list: [
                        { url: '/landingpage/rabatt-code/l/6bz2q', messageId: 'discount' },
                        { url: '/gutschein/gutschein-einloesen/l/wiren', messageId: 'redeem' },
                        // { url: '/landingpage/gutschein-pruefen/l/z94tk', messageId: 'exchange' },
                    ],
                },
            ];
        case TenantEnum.JS_AT:
            return [
                {
                    title: 'searchTermsTitle',
                    list: [
                        { url: '/geschenke/geschenkgutscheine/l/pvfi6', messageId: 'voucher' },
                        {
                            url: '/essen-trinken/dinner-dark-oesterreich/p/uno4m',
                            messageId: 'darkDinner',
                        },
                        { url: '/geschenke/abenteuer/l/kdqao', messageId: 'adventure' },
                        { url: '/geschenke/ausgefallene-geschenke/l/cyk41', messageId: 'fancy' },
                    ],
                },
                {
                    list: [
                        { url: '/geschenke/geschenk-freundin/l/u1hop', messageId: 'friend' },
                        { url: '/geschenke/besondere-geschenke/l/sqae7', messageId: 'special' },
                        {
                            url: '/candle-light-dinner-oesterreich/p/bstvg',
                            messageId: 'candleDinner',
                        },
                        { url: '/geschenke/fuer-maenner/l/83j6y', messageId: 'men' },
                    ],
                },
                {
                    list: [
                        {
                            url: '/fliegen-fallen/fallschirmspringen/l/695dr',
                            messageId: 'skydiving',
                        },
                        {
                            url: '/kunst-kultur-lifestyle/Fotoshooting/p/3uc6o',
                            messageId: 'photoShoot',
                        },
                        { url: '/fliegen-fallen/Segelfliegen/p/i9uo3', messageId: 'gliding' },
                    ],
                },
                {
                    list: [
                        {
                            url: '/geschenke/aussergewoehnliche-geschenke/l/tg8hi',
                            messageId: 'extraordinary',
                        },
                        { url: '/geschenke/geschenkideen/l/rzm9g', messageId: 'ideas' },
                        { url: '/arena/jochen-schweizer-arena/l/eh93i', messageId: 'arena' },
                    ],
                },
                {
                    title: 'onlineHelpTitle',
                    list: [
                        { url: '/landingpage/rabatt-code/l/6bz2q', messageId: 'discount' },
                        { url: '/gutschein/gutschein-einloesen/l/wiren', messageId: 'redeem' },
                        // { url: '/landingpage/gutschein-pruefen/l/z94tk', messageId: 'exchange' },
                    ],
                },
            ];
        case TenantEnum.MD_DE:
        case TenantEnum.MD_AT:
        case TenantEnum.MD_CH:
            return [
                {
                    title: 'searchTermsTitle',
                    list: [
                        {
                            url: '/geschenkidee/geschenke-fuer-paare/l/g2oad',
                            messageId: 'giftsForCouples',
                        },
                        {
                            url: '/geschenkidee/geschenke-fuer-maenner/l/pisx9',
                            messageId: 'giftsForMen',
                        },
                        {
                            url: '/geschenkidee/geschenke-fuer-frauen/l/r2wnt',
                            messageId: 'giftsForWomen',
                        },
                        {
                            url: '/kurzurlaub/l/g6ukx',
                            messageId: 'shortBreak',
                        },
                        {
                            url: '/wellness/l/s4mj8',
                            messageId: 'wellness',
                        },
                    ],
                },
                {
                    list: [
                        {
                            url: '/kurzurlaub/wellnessurlaub/l/qu6o1',
                            messageId: 'wellnessVacation',
                        },
                        {
                            url: '/kurzurlaub/wellness-wochenende/l/komq4',
                            messageId: 'wellnessWeekend',
                        },
                        {
                            url: '/fliegen/ballonfahrt/l/e8at1',
                            messageId: 'ballonRide',
                        },
                        {
                            url: '/fliegen/fallschirmspringen/l/g8ki4',
                            messageId: 'skydiving',
                        },
                        {
                            url: '/kurzurlaub/wellnesshotel/l/gb6tm',
                            messageId: 'wellnessHotel',
                        },
                    ],
                },
                {
                    list: [
                        {
                            url: '/geschenkidee/geschenke-zum-jahrestag/l/rsa7e',
                            messageId: 'anniversaryGifts',
                        },
                        {
                            url: '/wertgutscheine/l/z64ce',
                            messageId: 'valueVouchers',
                        },
                        {
                            url: '/kurzurlaub/romantisches-wochenende/l/gj5c4',
                            messageId: 'romanticWeekend',
                        },
                        {
                            url: '/kulinarische-geschenke/dinner-in-the-dark/l/nh1rd',
                            messageId: 'darkDinner',
                        },
                        {
                            url: '/action-geschenke/alpaka-wanderung/l/2cdvo',
                            messageId: 'alpacaHike',
                        },
                    ],
                },
                {
                    list: [
                        {
                            url: '/kulinarische-geschenke/candle-light-dinner/l/5puax',
                            messageId: 'candleDinner',
                        },
                        {
                            url: '/geschenkidee/weihnachtsgeschenke/l/cyjoe',
                            messageId: 'christmasPresents',
                        },
                        {
                            url: '/kulinarische-geschenke/weinprobe/l/h5ez3',
                            messageId: 'wineTasting',
                        },
                        {
                            url: '/kurzurlaub/aussergewoehnliche-hotels/l/nmb4c',
                            messageId: 'exceptionalHotels',
                        },
                        {
                            url: '/motorsport/quadfahren/l/9r743',
                            messageId: 'drivingQuad',
                        },
                    ],
                },
                {
                    title: 'onlineHelpTitle',
                    list: [
                        { url: '/kampagnen/rabattcode/l/h83q1', messageId: 'discount' },
                        { url: '/gutschein-einloesen/l/hbdmw', messageId: 'redeem' },
                    ],
                },
            ];
    }
}
