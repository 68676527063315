import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { PureLink } from '@jsmdg/yoshi';

import { SocialMediaIconDetail } from '../../../shared/types/tenantConfig';
import { FooterContext } from '../../types';
import { SocialIcon } from './SocialIcon';

const SocialApps = (): JSX.Element => {
    const { tenantConfig } = useFragmentContext<FooterContext>();
    return (
        <div className="p-2x d-flex justify-content-center align-items-center">
            {tenantConfig.socialMedia.map((item: SocialMediaIconDetail) => (
                <PureLink
                    href={item.url}
                    target="_blank"
                    title={item.title}
                    key={item.iconName}
                    className="my-0 mx-1x d-flex"
                >
                    <SocialIcon name={item.iconName} color="black" />
                </PureLink>
            ))}
        </div>
    );
};

export { SocialApps };
