import { createFragment } from '@jsmdg/react-fragment-scripts/fragment';

import { Footer } from './components/Footer';
import { RootComponentProps } from '../shared/types/rootComponentProps';
import { getTenantConfig } from './tenantConfigs/tenantConfig';

async function initDependencies(): Promise<void> {
    await new Promise<void>(resolve => resolve());
}

const { Fragment, init } = createFragment<RootComponentProps>(
    Footer,
    ({ locale, tenant, rootComponentProps }) => {
        return {
            tenant,
            locale,
            ...rootComponentProps,
            tenantConfig: {
                ...getTenantConfig(tenant, rootComponentProps.sharedData),
                ...rootComponentProps.sharedData,
            },
            featureFlagsVariation: rootComponentProps.featureFlagsVariation,
        };
    },
    initDependencies,
);

export { Fragment, init };
