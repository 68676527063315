import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { FooterContext } from '../../types';
import { FooterLink } from '../../../shared/types/tenantConfig';
import { FooterLinkItem } from './FooterLink/FooterLinkItem';
import styles from './FooterLinkList.module.scss';

const messages = defineMessages({
    faq: { defaultMessage: 'FAQs' },
    contact: { defaultMessage: 'Kontakt' },
    paymentMethods: { defaultMessage: 'Zahlungsarten' },
    aboutUs: { defaultMessage: 'Über uns' },
    jobs: { defaultMessage: 'Jobs' },
    jobsAndCareers: { defaultMessage: 'Jobs & Karriere' },
    affiliateProgram: { defaultMessage: 'Partnerprogramm' },
    press: { defaultMessage: 'Presse' },
    termsAndConditions: { defaultMessage: 'AGB' },
    privacy: { defaultMessage: 'Datenschutz' },
    termsOfUse: { defaultMessage: 'Nutzungsbedingungen' },
    onlineMarketplace: { defaultMessage: 'Online-Marktplatz' },
    legalNotice: { defaultMessage: 'Impressum' },
    shipping: { defaultMessage: 'Versand und Lieferung' },
    payback: { defaultMessage: 'PAYBACK' },
    cookieSettings: { defaultMessage: 'Cookie Einstellungen' },
    compliance: { defaultMessage: 'Compliance' },
    trustedShops: { defaultMessage: 'Trusted Shops' },
    magazin: { defaultMessage: 'Magazin' },
});

const FooterLinkList = (): JSX.Element => {
    const intl = useIntl();
    const { tenantConfig, isFirstLinkLineEnabled } = useFragmentContext<FooterContext>();

    const firstLineLinks = tenantConfig.footerLinks[0];
    const secondLineLinks = tenantConfig.footerLinks[1];

    return (
        <>
            {isFirstLinkLineEnabled && (
                <div
                    className={classNames(
                        'd-flex justify-content-center flex-wrap',
                        styles.linksWrapper,
                    )}
                >
                    {firstLineLinks.map(
                        ({ url, iconAltText, trackingId, messageId }: FooterLink) => (
                            <FooterLinkItem
                                key={trackingId}
                                trackingId={trackingId}
                                url={url}
                                iconAltText={iconAltText}
                                message={intl.formatMessage(messages[messageId])}
                                className="mx-1x"
                            />
                        ),
                    )}
                </div>
            )}
            <div
                className={classNames(
                    'd-flex justify-content-center flex-wrap',
                    styles.linksWrapper,
                )}
            >
                {secondLineLinks.map(({ url, iconAltText, trackingId, messageId }: FooterLink) => (
                    <FooterLinkItem
                        key={trackingId}
                        trackingId={trackingId}
                        url={url}
                        iconAltText={iconAltText}
                        message={intl.formatMessage(messages[messageId])}
                        className="mx-1x"
                    />
                ))}
            </div>
        </>
    );
};

export { FooterLinkList };
