import { defineMessages, useIntl } from 'react-intl';
import { Link, LinkVariant, LockIcon, PhoneIcon, TicketIcon } from '@jsmdg/yoshi';
import { CountryCode, Tenant } from '@jsmdg/tenancy';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import classNames from 'classnames';

import styles from './CheckoutFooter.module.scss';
import { PaymentMethods } from '../PaymentMethods/PaymentMethods';
import { FooterContext } from '../../types';
import { CheckoutFooterLink } from '../../../shared/types/tenantConfig';
import { FooterLinkItem } from '../FooterLinkList/FooterLink/FooterLinkItem';

const messages = defineMessages({
    paymentMethodsTitle: { defaultMessage: 'Zahlungsarten' },
    advantages: { defaultMessage: 'Vorteile' },
    securePayment: { defaultMessage: 'Sicher bezahlen mit SSL-Verschlüsselung' },
    voucherValidtyDE: { defaultMessage: 'Gutschein 3 Jahre gültig' },
    voucherValidtyAT: { defaultMessage: 'Gutschein flexibel einlösbar' },
    helpBoxTitle: { defaultMessage: 'Fragen zur Bestellung?' },
    normalServiceHoursTitle: { defaultMessage: 'Du erreichst uns zu folgenden Zeiten:' },
    christmasServiceHoursTitleStarts: { defaultMessage: 'Verlängerte Servicezeiten ab dem' },
    christmasServiceHoursTitleEnd: { defaultMessage: '1. Advent:' },
    termsAndConditions: { defaultMessage: 'AGB' },
    cookieSettings: { defaultMessage: 'Cookie Einstellungen' },
    privacy: { defaultMessage: 'Datenschutz' },
    termsOfUse: { defaultMessage: 'Nutzungsbedingungen' },
    onlineMarketplace: { defaultMessage: 'Online-Marktplatz' },
    compliance: { defaultMessage: 'Compliance' },
    legalNotice: { defaultMessage: 'Impressum' },
    copyRightIcon: { defaultMessage: '©' },
});

const CheckoutFooter = (): JSX.Element => {
    const intl = useIntl();
    const { enabledPaymentMethods, tenant, tenantConfig } = useFragmentContext<FooterContext>();

    const paymentContent = (
        <>
            <span className="theme-text-subtitle-text d-block mb-2x text-uppercase">
                {intl.formatMessage(messages.paymentMethodsTitle)}
            </span>
            <PaymentMethods enabledMethods={enabledPaymentMethods} />
        </>
    );

    const advantagesContent = (
        <>
            <span className="theme-text-subtitle-text d-block mb-2x text-uppercase">
                {intl.formatMessage(messages.advantages)}
            </span>
            <span className="theme-text-body-text d-block mb-2x">
                <LockIcon className="d-inline-block align-bottom mr-0-5x" />
                {intl.formatMessage(messages.securePayment)}
            </span>
            <span className="theme-text-body-text d-block">
                <TicketIcon className="d-inline-block align-bottom mr-0-5x" />
                <span>
                    {intl.formatMessage(
                        Tenant[tenant].belongsTo(CountryCode.Germany)
                            ? messages.voucherValidtyDE
                            : messages.voucherValidtyAT,
                    )}
                </span>
            </span>
            <div className="mt-2x">
                <div id="trustbadgeCustomContainer" />
                <div id="trustbadgeCustomMobileContainer" />
            </div>
        </>
    );

    const questionsContent = (
        <>
            <div className="theme-text-subtitle-text text-uppercase">
                {intl.formatMessage(messages.helpBoxTitle)}
            </div>
            <div className="theme-text-body-text mt-2x">
                <PhoneIcon className="d-inline-block align-bottom mr-0-5x" />
                <span className="d-none d-sm-inline-block">
                    {tenantConfig.contactInfo.phone.label}
                </span>
                <Link
                    className="d-inline-block d-sm-none"
                    variant={LinkVariant.GreyQuiet}
                    href={`tel:${tenantConfig.contactInfo.phone.number}`}
                >
                    {tenantConfig.contactInfo.phone.label}
                </Link>
            </div>
            {tenantConfig.serviceHours.normalServiceHours ? (
                <div className="theme-text-body-text mt-2x">
                    <span>{intl.formatMessage(messages.normalServiceHoursTitle)}</span>
                    <span className="d-block mt-1x mt-sm-1x">
                        {tenantConfig.serviceHours.normalServiceHours}
                    </span>
                </div>
            ) : null}
            {tenantConfig.serviceHours.christmasServiceHours ? (
                <div className="theme-text-body-text mt-2x">
                    <span>{intl.formatMessage(messages.christmasServiceHoursTitleStarts)}</span>
                    <span> </span>
                    <span className="fw-bold text-nowrap">
                        {intl.formatMessage(messages.christmasServiceHoursTitleEnd)}
                    </span>
                    <span className="d-block mt-1x mt-sm-1x">
                        {tenantConfig.serviceHours.christmasServiceHours}
                    </span>
                </div>
            ) : null}
        </>
    );

    const linkBoxContent = (
        <>
            {tenantConfig.checkoutLinks.map((linkItem: CheckoutFooterLink) => {
                return (
                    <FooterLinkItem
                        url={linkItem.url}
                        message={intl.formatMessage(messages[linkItem.messageId])}
                        trackingId={linkItem.trackingId}
                        className={classNames('mr-2x', styles.link)}
                    />
                );
            })}
            <span
                className={classNames('heme-text-body-text d-block ml-lg-auto', styles.brandName)}
            >
                {`${intl.formatMessage(messages.copyRightIcon)} ${tenantConfig.brandName}`}
            </span>
        </>
    );

    return (
        <footer className={styles.checkoutFooter}>
            <div
                className={classNames(
                    'container px-3x px-sm-6x pt-2x pb-9x py-md-2x', // pb-10x is needed to allocate some space for "Weiter zu den Bestelldaten" button
                    styles.checkoutFooterWrapper,
                )}
            >
                <div className="grid">
                    <div className="g-col-12 g-col-sm-4 mb-2x mb-4x">{paymentContent}</div>
                    <div className="g-col-12 g-col-sm-4 mb-2x mb-4x">{advantagesContent}</div>
                    <div className="g-col-12 g-col-sm-4 mb-2x mb-2x">{questionsContent}</div>
                    <div
                        className={classNames(styles.linkBox, 'g-col-12 pt-2x', 'd-flex flex-wrap')}
                    >
                        {linkBoxContent}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export { CheckoutFooter };
